import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../../components/0-layout"
import Images from "../../components/image"
import Query from "../../components/query"
import SEO from "../../components/seo"
import "../../scss/company/kaisya.scss"

/**
 * @property {object} allCompanyJson
 */

const Jp = () => {
  const data = Query()

  const c = useStaticQuery(graphql`
      query CompanyQuery {
          allCompanyJson {
              edges {
                  node {
                      profile{
                          th
                          td
                      }
                      history {
                          year
                          month
                          content
                      }
                  }
              }
          }
      }
  `)

  let trProfile = []
  c.allCompanyJson.edges[0].node.profile.forEach(i =>
    trProfile.push(
      <tr>
        <th style={{ whiteSpace: "pre-line" }}>{i.th}</th>
        <td style={{ whiteSpace: "pre-line" }}>{i.td}</td>
      </tr>,
    ),
  )

  let trHistory = []
  c.allCompanyJson.edges[1].node.history.forEach(i =>
    trHistory.push(
      <tr>
        <th style={{ whiteSpace: "pre-line" }}>{i.year}</th>
        <td style={{ whiteSpace: "pre-line" }} className={"month"}>{i.month}</td>
        <td style={{ whiteSpace: "pre-line" }}>{i.content}</td>
      </tr>,
    ),
  )

  return (
    <Layout sitePath={"jp"} pageTitle={data.allFooterJson.edges[3].node.li}>

      <SEO title={data.allFooterJson.edges[3].node.li}/>

      <h2 className={"title-blue"}>{data.allFooterJson.edges[3].node.li}</h2>

      <table className={"company"} id={"profile"}>
        {trProfile}

        <tr>
          <th>&nbsp;</th>

          <td id={"licenses"}>
            <div>
              <Images filename={"profile/0.jpg"}/>
            </div>

            <div>
              <Images filename={"profile/1.jpg"}/>
            </div>
          </td>
        </tr>
      </table>

      <h2 className={"title-blue"}>沿 革</h2>

      <table className={"company"} id={"history"}>
        {trHistory}
      </table>

    </Layout>
  )
}

export default Jp
